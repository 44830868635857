import { businessConstants } from "./constants";
import { businessServices } from "./services";
import { notificationConstants } from "CommonUI/Notification/_store/constants";
import { getBusinessesNames, getBusinessesLocalisations } from "./helper";
import _ from "lodash";

class BusinessActions {
  // TODO: Remove this spaghetti code in future
  getList(filters = {}) {
    return (dispatch) => {
      if (_.isObject(JSON.parse(sessionStorage.getItem("business")))) {
        let response = JSON.parse(sessionStorage.getItem("business"));
        let list = response.result;
        if (list) {
          dispatch({ type: businessConstants.SET_LIST, list });
        }
        if (_.isEmpty(filters)) {
          const names = getBusinessesNames(list);
          const localisations = getBusinessesLocalisations(list);
          dispatch({ type: businessConstants.SET_FILTER_NAMES, names });
          dispatch({
            type: businessConstants.SET_FILTER_LOCALISATIONS,
            localisations,
          });
        }
        dispatch({
          type: businessConstants.SET_LIST_IS_FILTERED,
          filtered: !_.isEmpty(filters),
        });
        return response;
      } else {
        return businessServices.getList(filters).then((response) => {
          if (response.type === notificationConstants.SUCCESS_RESPONSE) {
            let list = response.result;
            if (list) {
              dispatch({ type: businessConstants.SET_LIST, list });
            }
            if (_.isEmpty(filters)) {
              const names = getBusinessesNames(list);
              const localisations = getBusinessesLocalisations(list);
              dispatch({ type: businessConstants.SET_FILTER_NAMES, names });
              dispatch({
                type: businessConstants.SET_FILTER_LOCALISATIONS,
                localisations,
              });
            }
            dispatch({
              type: businessConstants.SET_LIST_IS_FILTERED,
              filtered: !_.isEmpty(filters),
            });
          }
          return response;
        });
      }
    };
  }

  setChosenBusiness(id) {
    return (dispatch) => {
      if (_.isObject(JSON.parse(sessionStorage.getItem("business")))) {
        let response = JSON.parse(sessionStorage.getItem("business"));
        let list = response.result;
        if (list && typeof list !== "string") {
          const item = list.find((item) => {
            return item.id === parseInt(id);
          });
          if (item) {
            dispatch({ type: businessConstants.SET_CHOSEN_BUSINESS, item });
          }
        }
        return response;
      } else {
        return businessServices.getList().then((response) => {
          if (response) {
            let list = response.result;
            if (list && typeof list !== "string") {
              const item = list.find((item) => {
                return item.id === parseInt(id);
              });
              if (item) {
                dispatch({ type: businessConstants.SET_CHOSEN_BUSINESS, item });
              }
            }
            return response;
          }
        });
      }
    };
  }

  setChosenProduct(id) {
    return (dispatch) => {
      return businessServices.getProductData(id).then((response) => {
        if (response) {
          let item = response.result;
          if (item) {
            dispatch({ type: businessConstants.SET_CHOSEN_PRODUCT, item });
          }
        }

        return response;
      });
    };
  }

  setSearchedProducts(filter) {
    return (dispatch) => {
      return businessServices.searchProducts(filter).then((response) => {
        if (response) {
          let item = response.result;
          if (item) {
            dispatch({ type: businessConstants.SET_SEARCHED_PRODUCTS, item });
          }
        }

        return response;
      });
    };
  }
}

export const businessActions = new BusinessActions();
