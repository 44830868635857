import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { connectedRoutes } from "../../../Layout/Connected/constants";
import Progress from "react-progress-2";
import Button from "../../../CommonUI/Button";
import _ from "lodash";
import { breadcrumbActions } from "../../../CommonUI/Breadcrumb/actions";
import CheckboxTree from "react-checkbox-tree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faChevronRight,
  faChevronDown,
  faPlusSquare,
  faMinusSquare,
  faSquare,
  faFolderOpen,
  faFolder,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { useProductsQuery, useCategories } from "../../state";

const getCategoryLeavesValues = (cat) => {
  const recurse = (currentCategory, acc) => {
    if (currentCategory.children && currentCategory.children.length) {
      for (const child of currentCategory.children) recurse(child, acc);
    } else {
      acc.push(+currentCategory.value);
    }
  };
  const ret = [];
  recurse(cat, ret);
  return ret;
};

const getCategoryValues = (cat) => {
  const recurse = (currentCategory, acc) => {
    acc.push(+currentCategory.value);
    if (currentCategory.children) {
      for (const child of currentCategory.children) recurse(child, acc);
    }
  };
  const ret = [];
  recurse(cat, ret);
  return ret;
};

export default (props) => {
  const dispatch = useDispatch();
  const chosenBusiness = useSelector((store) => {
    return store.business.chosenBusiness;
  });

  const params = useParams();

  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);

  const categoriesQuery = useCategories();
  const { subCategoryId } = props.location;
  const { t } = useTranslation("business");

  useEffect(() => {
    dispatch(
      breadcrumbActions.push(
        <NavLink to={`${connectedRoutes.BUSINESS}/${props.match.params.id}`}>
          {t("catalogue.title")}
        </NavLink>
      )
    );

    return () => dispatch(breadcrumbActions.pop());
  }, [dispatch, props.match.params.id, t]);

  const productsQuery = useProductsQuery({ subcategoryIds: checked });

  useEffect(() => {
    if (productsQuery.isLoading) Progress.show();
    else Progress.hide();
  }, [productsQuery.isLoading]);

  const onCheck = (targetNode) => {
    categoriesQuery
      .ensureChildrenAreFetched(+targetNode.value)
      .then((completeCategory) =>
        setChecked((checked) => {
          const completeCategoryValuesExpanded =
            getCategoryLeavesValues(completeCategory);
          if (targetNode.checked) {
            return _.union(checked, completeCategoryValuesExpanded);
          }
          return _.difference(checked, completeCategoryValuesExpanded);
        })
      );
  };

  const onExpand = (targetNode) => {
    categoriesQuery
      .ensureChildrenAreFetched(+targetNode.value)
      .then(() =>
        setExpanded((expanded) =>
          targetNode.expanded
            ? [...expanded, targetNode.value]
            : expanded.filter((c) => c !== targetNode.value)
        )
      );
  };

  const chosenCategory = categoriesQuery.categories?.find((c) =>
    c.children.some((subCat) => subCat.id === parseInt(params.subcategoryId))
  );
  const chosenSubCategory = chosenCategory?.children.find(
    (c) => c.id === parseInt(params.subcategoryId)
  );
  const subCategoryIsValid = () =>
    chosenSubCategory &&
    (subCategoryId === chosenSubCategory.id ||
      (subCategoryId === undefined && chosenSubCategory.id !== null));
  useEffect(() => {
    // when chosenSubCategory changes
    if (!chosenSubCategory || !categoriesQuery.categories) return;

    // make sure the category has all of its children fetched
    categoriesQuery
      .ensureChildrenAreFetched(chosenSubCategory.value)
      .then((completeCategory) => {
        setExpanded((expanded) =>
          _.union(expanded, getCategoryValues(completeCategory))
        );
        setChecked((checked) =>
          _.union(checked, getCategoryLeavesValues(completeCategory))
        );
      });
  }, [chosenSubCategory?.value]);

  if (!categoriesQuery.categories) {
    return <>Loading...</>;
  }

  return (
    <Fragment>
      <div className="container row pb-0">
        <div className="col-xs-12">
          {chosenBusiness && subCategoryIsValid() && chosenCategory && (
            <Fragment>
              <span className="color-gray">{t("welcome")} / </span>
              <NavLink
                activeClassName="text-decoration-none"
                to={`${connectedRoutes.BUSINESS}/${chosenBusiness.id}`}
                className="color-20527a"
              >
                {chosenBusiness.name}&nbsp;/&nbsp;
              </NavLink>
              <span className="color-20527a">{chosenCategory.label}</span>
            </Fragment>
          )}
        </div>
      </div>
      <div className="container">
        <div className="catalogue-container row">
          {chosenBusiness &&
            chosenCategory &&
            subCategoryIsValid() &&
            chosenCategory.children.length > 0 && (
              <div
                key={`cat${chosenCategory.id}`}
                className="subcategories-container"
              >
                <p className="title">{t("catalogue.categories")}</p>
                <CheckboxTree
                  nodes={chosenCategory.children}
                  checked={checked}
                  expanded={expanded}
                  onCheck={(_, targetNode) => onCheck(targetNode)}
                  onExpand={(_, targetNode) => onExpand(targetNode)}
                  icons={{
                    check: (
                      <FontAwesomeIcon
                        className="rct-icon rct-icon-check"
                        icon={faCheckSquare}
                      />
                    ),
                    uncheck: (
                      <FontAwesomeIcon
                        className="rct-icon rct-icon-uncheck"
                        icon={faSquare}
                      />
                    ),
                    halfCheck: (
                      <FontAwesomeIcon
                        className="rct-icon rct-icon-half-check"
                        icon={faCheckSquare}
                      />
                    ),
                    expandClose: (
                      <FontAwesomeIcon
                        className="rct-icon rct-icon-expand-close"
                        icon={faChevronRight}
                      />
                    ),
                    expandOpen: (
                      <FontAwesomeIcon
                        className="rct-icon rct-icon-expand-open"
                        icon={faChevronDown}
                      />
                    ),
                    expandAll: (
                      <FontAwesomeIcon
                        className="rct-icon rct-icon-expand-all"
                        icon={faPlusSquare}
                      />
                    ),
                    collapseAll: (
                      <FontAwesomeIcon
                        className="rct-icon rct-icon-collapse-all"
                        icon={faMinusSquare}
                      />
                    ),
                    parentClose: (
                      <FontAwesomeIcon
                        className="rct-icon rct-icon-parent-close"
                        icon={faFolder}
                      />
                    ),
                    parentOpen: (
                      <FontAwesomeIcon
                        className="rct-icon rct-icon-parent-open"
                        icon={faFolderOpen}
                      />
                    ),
                    leaf: (
                      <FontAwesomeIcon
                        className="rct-icon rct-icon-leaf-close"
                        icon={faFile}
                      />
                    ),
                  }}
                />
              </div>
            )}
          {
            <div className="products-container">
              {productsQuery.total !== 0 && (
                <p className="title">
                  {t("catalogue.product_list")} ({productsQuery.products.length}{" "}
                  / {productsQuery.total})
                </p>
              )}
              {subCategoryIsValid() &&
                chosenBusiness &&
                productsQuery.products?.map((item, index) => {
                  return (
                    <div
                      key={`prod${item.product.id}${index}`}
                      className="product-item"
                    >
                      {
                        <NavLink
                          to={`${connectedRoutes.BUSINESS}/${chosenBusiness.id}/product/${item.product.id}`}
                          className="product-link tooltip"
                        >
                          {item.product.name}
                          {item.product.supplier && (
                            <span className="">
                              &nbsp;&nbsp;({item.product.supplier})
                            </span>
                          )}
                          {item.product.price && (
                            <span className="bold">
                              &nbsp;&nbsp;{item.product.price}€
                            </span>
                          )}

                          <span className="tooltiptext">
                            {item.product.subcategory_name}
                          </span>
                        </NavLink>
                      }
                    </div>
                  );
                })}
            </div>
          }
        </div>
        {productsQuery.total > 100 &&
          productsQuery.total !== productsQuery.products.length && (
            <Button
              className="primary next_list"
              onClick={productsQuery.loadMore}
              label={t("product_list.next")}
              type="submit"
            />
          )}
      </div>
    </Fragment>
  );
};
