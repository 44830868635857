import React from 'react';
import { withTranslation } from 'react-i18next';
import './style.scss';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import Select from 'react-select'
import Progress from 'react-progress-2';
import { punchoutService } from '../Punchout/_store/service';
import Button from '../CommonUI/Button';
import { notificationConstants } from 'CommonUI/Notification/_store/constants';
import { notificationActions } from 'CommonUI/Notification/_store/actions';
import { Redirect } from 'react-router';
import { connectedRoutes } from '../Layout/Connected/constants';

const SelectSupplierSchema = () => Yup.object().shape({
    supplier: Yup.number(),
    business: Yup.number(),
});


withTranslation('layout')(SelectSupplierSchema);

class SelectSupplierModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            initInputs: {
                supplier: null,
                business: null,
            },
            supplierId: null,
            businessId: null,
            suppliers: [],
            businesses: null,
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.hasOwnProperty('showModal') && this.props.showModal === true && nextProps.showModal === false) {
            this.clearFormData();
        }
        console.log(this.state.businesses);
        if (nextProps.hasOwnProperty('business') && !this.state.businesses && this.state.businesses !== undefined) {
            this.setState({
                businesses: nextProps.business,
            });
            let startBusiness = null;
            if (nextProps.business && nextProps.business.length === 1) {
                startBusiness = nextProps.business[0];
                this.setState({
                    businessId: startBusiness ? startBusiness.id : null,
                });
            }
        }

        return true;
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
        });

        let startBusiness = null;
        if (this.props.business && this.props.business.length === 1) {
            startBusiness = this.props.business[0];
            this.setState({
                businessId: startBusiness ? startBusiness.id : null,
            });
        }

        Progress.show();
        punchoutService.getPunchoutSuppliersList().then(response => {
            if (response.result && Array.isArray(response.result)) {
                this.setState({
                    suppliers: this.prepareOptions(response.result),
                });
            }

            this.setState({
                isLoading: false,
            });
            Progress.hide();
        });
    }

    componentWillUnmount() {
        this.clearFormData();
    }

    clearFormData = () => {
        this.setState({
            initInputs: {
                supplier: null,
                business: null,
            },
            redirect: false,
            supplierId: null,
            businessId: null,
        });
    }

    handleSubmit = (...args) => {
        const {supplierId, businessId} = this.state;
        const {t, dispatch} = this.props;

        if (!supplierId || !businessId) {
            const response = {
                type: notificationConstants.ERROR_RESPONSE,
                result: t('invalid_form')
            };

            dispatch(notificationActions.add(response, t('invalid_form')));
            return;
        }

        this.setState({
            redirect: true,
        });
    }

    prepareOptions = (data) => {
        const arr = [];
        if (data) {
            data.forEach(item => {
                arr.push({
                    value: item.id,
                    label: item.name,
                });
            });
        }

        return arr;
    }

    supplierChange = ({value, label}, setFieldValue) => {
        this.setState({
            supplierId: value,
        });
        setFieldValue('supplier', value);
    }

    businessChange = ({value, label}, setFieldValue) => {
        this.setState({
            businessId: value,
        });
        setFieldValue('business', value);
    }

    render() {
        const {t, business} = this.props;
        const {supplierId, businessId, suppliers, isLoading, businesses} = this.state;
        const {initialValues} = {
            businessId,
            supplierId: null,
        };
        const sanitizedBusiness = this.prepareOptions(business);

        let defaultBusiness = null;
        if (businesses && businesses.length === 1) {
            defaultBusiness = {
                value: businesses[0].id,
                label: businesses[0].name,
            };
        }

        if (this.state.redirect) {
            return <Redirect to={`${connectedRoutes.PUNCHOUT}/${businessId}/${supplierId}`} />;
        }

        return (
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={this.handleSubmit.bind(this)}
                validationSchema={SelectSupplierSchema}
            >
                {({errors, touched, isSubmitting, setFieldValue, values}) => (
                    <Form className="form-inline">
                        <div className="row">
                            <div className="col-md-3">
                                <label className="form-label">{t('modal.label.business')}</label>
                            </div>
                            <div className="col-md-9">
                                {defaultBusiness ?
                                    <Select
                                    options={sanitizedBusiness}
                                    name="business"
                                    value={defaultBusiness}
                                    onChange={(event) => this.businessChange(event, setFieldValue)} /> :

                                    <Select
                                        options={sanitizedBusiness}
                                        name="business"
                                        onChange={(event) => this.businessChange(event, setFieldValue)} />
                                }

                            </div>
                        </div>

                        <div className="row mt-1">
                            <div className="col-md-3">
                                <label className="form-label">{t('modal.label.supplier')}</label>
                            </div>
                            <div className="col-md-9">
                                <Select
                                    options={suppliers}
                                    name="supplier"
                                    isLoading={isLoading}
                                    error={errors.supplier ? t(errors.supplier) : ''}
                                    touched={touched.supplier}
                                    onChange={(event) => this.supplierChange(event, setFieldValue)} />
                            </div>
                        </div>
                        <div className="row mt-1 submit-button">
                            <Button
                                isLoading={isLoading}
                                label={t('button.next')}
                                type="submit"
                                />
                            </div>
                    </Form>
                )}
            </Formik>
        );
    }
}

export default withTranslation('punchout')(SelectSupplierModal);
