import React from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { useCategories } from "../../state";
import { connectedRoutes } from "Layout/Connected/constants";
import _ from "lodash";

export default () => {
  const { t } = useTranslation("business");
  const { categories } = useCategories();
  const chosenBusiness = useSelector((store) => store.business.chosenBusiness);
  const affairId = useParams().id;

  return (
    <div className="container categories">
      {chosenBusiness && affairId && categories && (
        <h4 className="categories__title">{t("categories_title")}</h4>
      )}
      <div className="categories__container">
        {chosenBusiness &&
          affairId &&
          categories &&
          categories.map((category) => {
            return (
              !_.isEmpty(category.children) && (
                <ul key={category.id} className="category">
                  <li>
                    <h3 className="category__title">{category.label}</h3>
                  </li>

                  {category.children &&
                    category.children.length > 0 &&
                    category.children.map((subCategory) => {
                      return (
                        <li key={subCategory.id}>
                          <NavLink
                            className="category__subtitle"
                            to={{
                              pathname: `${connectedRoutes.BUSINESS}/${affairId}/catalogue/${subCategory.id}`,
                              subCategoryId: subCategory.id,
                            }}
                          >
                            {subCategory.label}
                          </NavLink>
                        </li>
                      );
                    })}
                </ul>
              )
            );
          })}
      </div>
    </div>
  );
};
