import React from "react";
import "../style.scss";
import _ from 'lodash';
import {TextInput} from 'CommonUI/FormFields';
import {useTranslation} from 'react-i18next';
import {Field} from 'formik';

export default({item, index, errors, touched}) => {
    const {t} = useTranslation("orders");

    return (
        <div className="row ">
            <div className="col-6 col-sm-4">
                <span className="name">{item.name}</span>
                <span className="reference">{t("ref_fab")}: {item.reference}</span>
                <span className="brand">{t("brand")}:{item.brand}</span>
            </div>
            <div className="col-6 col-sm-3">
               <Field  className="span-input" disabled name={`products.${index}.ordered_quantity`} />
            </div>
            <div className="col-6 col-sm-3">
                <Field  className="span-input" disabled name={`products.${index}.already_received_quantity`} />
            </div>
            <div className="col-6 col-sm-2 received-quantity">
                <TextInput
                    type="text"
                    name={`products.${index}.quantity`}
                    error={ _.isArray(errors.products) && errors.products[index] ? t(errors.products[index].quantity) : ''}
                    touched={_.isArray(touched.products) && touched.products[index] ? touched.products[index].quantity : false}
                />
                <TextInput
                    type="hidden"
                    name={`products.${index}.id`}
                />
            </div>
        </div>
    );
}
