import { httpService } from "_network/http/service";
import React, { useState, useEffect, createContext, useContext } from "react";
import { businessServices } from "./_store/services";
import { notificationConstants } from "CommonUI/Notification/_store/constants";
import { notificationActions } from "CommonUI/Notification/_store/actions";
import { useDispatch } from "react-redux";

const fakeChild = {
  id: "FAKE",
  label: "Loading...",
  value: "FAKE",
  showCheckBox: false,
  children: [],
};

const STORAGE_KEY = "categories";

const CategoriesContext = createContext(null);

export const CategoriesProvider = ({ children }) => {
  const [categories, setCategories] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const sessionCategories = sessionStorage.getItem(STORAGE_KEY);
    if (sessionCategories) {
      setCategories(JSON.parse(sessionCategories));
      return;
    }
    setIsLoading(true);
    httpService.get("/root_categories").then((response) => {
      if (response.type === "success") {
        const transform = (cat) => {
          const children =
            cat.hasChildCategories && cat.children.length === 0
              ? [fakeChild]
              : cat.children?.map(transform);
          return { ...cat, children, hasCompleteChildren: false };
        };
        const categoriesWithFakeChild = response.result.map(transform);
        setCategories(categoriesWithFakeChild);
        setIsLoading(false);
      } else {
        console.error(response);
      }
    });
  }, []);

  const ensureChildrenAreFetched = (catValue) => {
    // huita polnaya
    const findCategory = (current) => {
      if (current.value === catValue) return current;

      for (let cat of current.children || []) {
        if (current.value === catValue) return cat;
        const foundMaybe = findCategory(cat);
        if (foundMaybe !== undefined) return foundMaybe;
      }
      return undefined;
    };

    let category = undefined;
    for (let cat of categories) {
      const foundMaybe = findCategory(cat);
      if (foundMaybe !== undefined) category = foundMaybe;
    }

    if (category === undefined) {
      console.error("couldnt find any category in the tree", catValue);
    }

    if (!category.hasCompleteChildren) {
      setIsLoading(true);
      return httpService
        .get(`/child_categories/${category.value}`)
        .then((response) => {
          if (response.type === "success") {
            const resChildren = response.result;
            const complete = (resCat) => ({
              ...resCat,
              hasCompleteChildren: true,
              children: resCat.children?.map(complete),
            });

            let completedCat = undefined;

            const replaceChildrenSomewhere = (cat) => {
              if (cat.value === category.value) {
                completedCat = {
                  ...cat,
                  children: resChildren?.map(complete),
                  hasCompleteChildren: true,
                };

                return completedCat;
              }

              return {
                ...cat,
                children: cat.children?.map(replaceChildrenSomewhere),
              };
            };
            setCategories((categories) =>
              categories.map(replaceChildrenSomewhere)
            );
            setIsLoading(false);

            return completedCat;
          }
        });
    }

    return Promise.resolve(category);
  };

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(categories));
  }, [categories]);
  return (
    <CategoriesContext.Provider
      value={{
        categories,
        isLoading,
        ensureChildrenAreFetched,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

export const useCategories = () => useContext(CategoriesContext);

const limit = 100;

export const useProductsQuery = ({ subcategoryIds }) => {
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(0);
  const [products, setProducts] = useState(null);
  const [total, setTotal] = useState(0);

  const dispatch = useDispatch();

  useEffect(() => {
    setOffset(0);
  }, [subcategoryIds]);
  const loadMore = () => setOffset(offset + limit);

  useEffect(() => {
    setIsLoading(true);

    if (subcategoryIds === undefined || subcategoryIds.length === 0) {
      setIsLoading(false);
      setProducts(null);
      setOffset(0);
      setTotal(0);
      return;
    }
    businessServices
      .getProducts({ filter: { offset, subcategoryIds } })
      .then((response) => {
        if (response?.type === notificationConstants.SUCCESS_RESPONSE) {
          setProducts((products) => [
            ...(products || []).slice(0, offset),
            ...response.result[0],
          ]);
          setTotal(response.result.totalProducts);
        } else {
          dispatch(notificationActions.add(response));
        }
        setIsLoading(false);
      });
  }, [subcategoryIds, offset, dispatch]);

  return {
    loadMore,
    isLoading,
    products,
    total,
  };
};
