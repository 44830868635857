import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router";
import i18n from "_i18n";
import newStore from "./_store";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { Routes } from "routes";
import { history } from "_helpers/history";
import * as serviceWorker from "serviceWorker";
import "_assets/styles/base.scss";

const store = newStore();

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <Router history={history}>{Routes}</Router>
    </I18nextProvider>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
