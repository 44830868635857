import React from "react";
import "./List/style.scss";
import { withTranslation } from "react-i18next";
import routes from "./routes";
import { connect } from "react-redux";
import { businessActions } from "./_store/actions";
import { CategoriesProvider } from "./state";

class Business extends React.Component {
  constructor(props) {
    super(props);
    this.dispatch = props.dispatch;
  }

  componentDidMount() {
    if (this.props.businessState && this.props.businessState.list == null) {
      this.dispatch(businessActions.getList({}));
    }
  }

  render() {
    return <CategoriesProvider>{routes}</CategoriesProvider>;
  }
}

export default connect((state) => {
  const { business } = state;
  return { businessState: business };
})(withTranslation("business")(Business));
