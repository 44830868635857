import React, { useEffect } from "react";
import routes from "./routes";
import { useSelector, useDispatch } from "react-redux";
import { businessActions } from "../_store/actions";

export default ({ params }) => {
  const dispatch = useDispatch();
  const business = useSelector((store) => store.business);
  
  // TODO: only this left, need to remove this completely sooner or later
  useEffect(() => {
    if (params.id) {
      if (
        business.chosenBusiness == null ||
        business.chosenBusiness.id !== parseInt(params.id)
      ) {
        dispatch(businessActions.setChosenBusiness(params.id));
      }
    }
  }, [params.id, business.chosenBusiness, dispatch]);

  return <>{routes}</>;
};
